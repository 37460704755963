import { Container, Row, Col } from "react-bootstrap";
import Fade from 'react-reveal/Fade';

const Details = () => {
    return (
        <section id="details">
            <Container>
                <Fade left duration={500} delay={600} distance="30px">
                    <div className="mb-5 text-center"><h2>Come and explore Florida with us. . .</h2></div>
                </Fade>

                <Fade left duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details1.jpg")} alt="Details1" data-cursor-text="Check out"  />
                        </Col>

                        <Col lg={4} className="col-description">
                            <div>
                                <h3 style={{ fontWeight: 'normal' }}>South Beach</h3>
                                <p>South Beach is known for its beaches and the glamorous scene around its happening nightspots and celebrity-chef eateries.</p>
                            </div>
                        </Col>
                    </Row>
                </Fade>

                <Fade right duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={4} className="col-description">
                            <div>
                                <h3 style={{ fontWeight: 'normal' }}>Miami Design District</h3>
                                <p>The Design District is known for its sleek modern architecture, upscale interior design stores and art galleries. Luxury fashion and jewelry boutiques, cafes and celebrity-chef restaurants draw a well-heeled crowd.</p>
                            </div>
                        </Col>

                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details2.jpg")} alt="Details2" data-cursor-text="Check out" />
                        </Col>
                    </Row>
                </Fade>

                <Fade left duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details3.jpg")} alt="Details3" data-cursor-text="Check out"  />
                        </Col>

                        <Col lg={4} className="col-description">
                        <div>
                                <h3 style={{ fontWeight: 'normal' }}>Pérez Art Museum</h3>
                                <p>Pérez Art Museum Miami is a modern and contemporary art museum dedicated to collecting and exhibiting international art of the 20th and 21st centuries.</p>
                            </div>
                        </Col>
                    </Row>
                </Fade>

                <Fade right duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={4} className="col-description">
                            <div>
                                <h3 style={{ fontWeight: 'normal' }}>Wynwood</h3>
                                <p>Known for its many colorful murals, Wynwood is one of the city's most happening districts. Wynwood Walls is an outdoor museum showcasing large-scale works by some of the world's best-known street artists.</p>
                            </div>
                        </Col>

                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details7.jpg")} alt="Details2" data-cursor-text="Check out" />
                        </Col>
                    </Row>
                </Fade>

                <Fade left duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details4.jpg")} alt="Details4" data-cursor-text="Check out"  />
                        </Col>

                        <Col lg={4} className="col-description">
                        <div>
                                <h3 style={{ fontWeight: 'normal' }}>Orlando Amusement Parks</h3>
                                <p>Orlando is home to some of the world's top theme parks! See our guides to Walt Disney World, Universal Studios, SeaWorld and LEGOLAND.</p>
                            </div>
                        </Col>
                    </Row>
                </Fade>

                <Fade right duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={4} className="col-description">
                            <div>
                                <h3 style={{ fontWeight: 'normal' }}>St. Augustine</h3>
                                <p>St. Augustine is a city on the northeast coast of Florida. It lays claim to being the oldest city in the U.S., and is known for its Spanish colonial architecture as well as Atlantic Ocean beaches like sandy St. Augustine Beach and tranquil Crescent Beach. Anastasia State Park is a protected wildlife sanctuary.</p>
                            </div>
                        </Col>

                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details5.jpg")} alt="Details5" data-cursor-text="Check out" />
                        </Col>
                    </Row>
                </Fade>

                <Fade left duration={500} delay={600} distance="30px">
                    <Row className="mb-5 mb-lg-0">
                        <Col lg={8} className="p-4">
                            <img src={require("../Images/details/details6.jpg")} alt="Details6" data-cursor-text="Check out"  />
                        </Col>

                        <Col lg={4} className="col-description">
                        <div>
                                <h3 style={{ fontWeight: 'normal' }}>Florida Natural Springs</h3>
                                <p>The natural springs in Florida are the perfect place for swimming, kayaking, paddle boarding on SUPs, snorkeling, tubing, diving, and seeing wildlife. These are some of the best swimming holes in Florida because the waters are crystal clear and a constant 68 to 74 degrees making swimming possible year-round.</p>
                            </div>
                        </Col>
                    </Row>
                </Fade>


            </Container>
        </section>
    );
};

export default Details;