import { Container, Row, Col } from "react-bootstrap";



const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col lg={6} xs={12} className="footer-col mb-4 mb-md-0">
                        <div>
                            
                        </div>
                     
                    </Col>

                

                </Row>
            </Container>
        </footer>
    )
};

export default Footer;