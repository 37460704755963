import { Container, Row, Col } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
import { BiTaxi, BiBuildingHouse, BiRestaurant, BiMapAlt, BiShoppingBag, BiStar } from 'react-icons/bi';


const iconStyle = { fontSize: 36 };

const About = () => {
    return (
        <>
            <section id="about">
                <Fade left duration={500} delay={600} distance="30px">
                    <Container>
                    <div>
                        <p className="title">
                            Here at <span className="white-color" style={{ fontWeight: 'bold' }}>Elite Private Excursions</span> , our goal is to ensure you will have a worry free trip, from the moment you land, until you take off.
                        </p>
                        <p className="subtitle">
                        Whether it's a reservation, special arrangement, or just a request, we are here to provide the service.
                        We will drive you, ensure your safety and security, and guide you through the wonders of Florida; from the beaches in Miami, to the parks in Orlando, up to the springs in Central and North Florida, and to the forts in St. Augustine.
                        We speak multiple languages and possess the skills to make your experience unique and special.
                        </p>
                        </div>
                    </Container>
                </Fade>
            </section>

            <section id="about-p2">
                <Fade left duration={500} delay={600} distance="30px">
                    <Container>
                        <div className="mb-5"><h2>Services</h2></div>
                        <Row>
                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiTaxi style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Personal Driver</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Airport Pick-up and Drop-off.</p></div>
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiBuildingHouse style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Housing</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Hotel or airBNB, your choice or recommendation.</p></div>
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiRestaurant style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Dining</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Reservations for the best restaurants in the city.</p></div>
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiMapAlt style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Park Reservations</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Help secure theme park reservations for you and your family!</p></div>
                            </Col>
                        </Row>
                        
                    </Container>
                    <Container>
                        <div className="mb-5"></div>
                        <Row>
                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiShoppingBag style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Shopping</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Take you around the city to some of the most iconic stores.</p></div>
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                                <div style={{ marginBottom: 32 }}><span className="about-icon"><BiStar style={iconStyle} /></span></div>
                                <div><h5 className="secondary-color">Requests</h5></div>
                                <div style={{ marginLeft: 30, marginRight: 30 }}><p>Personal errands? Special arrangements? We got you covered.</p></div>
                            </Col>

                            <Col lg={3} md={3} sm={6} xs={12} className="mb-lg-0 mb-4">
                               
                            </Col>
                        </Row>
                        
                    </Container>
                </Fade>
            </section>
        </>
    );
};

export default About;