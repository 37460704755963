import { Container } from "react-bootstrap";
import Fade from 'react-reveal/Fade';

const Contact = () => {
    return (
        <section id="contact">
            <Container>
                <Fade bottom duration={500} delay={600} distance="30px">
                    <h1>About the founder</h1>
                    <p>35 Years in Florida with a successful career in high level security, Sefi is now dedicated to his other passion; the travel and tourism industry.
                    As a graduate of a local Florida university in Hospitality Management, Sefi's enthusiasm for travel and his love of people, as well as his understanding of upscale travelers' needs and desires, is the mere reason for the creation of Elite Tours Excursions.</p>
                    <p><b>Want to reach me?</b></p>
                    <a href="mailto:eliteprivateexcursions@gmail.com"><span className="button button-hero">Send me an e-mail</span></a>
                </Fade>
            </Container>
        </section>
    )
};

export default Contact;